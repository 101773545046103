/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import authenticate from '@/auth/authenticate';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      component: () => import('@/views/Login'),
     // beforeEnter: authenticate,
    },
 
    {   path: '/main',
    component: () => import('@/views/app/Index'),
    //beforeEnter: authenticate,
   // redirect: '/app/dashboard/analytic'
  children:[
    {
    path: '/catalogos',
    component: () => import('@/views/catalogos/Catalogos'),
   // beforeEnter: authenticate,
    group:'catalogos',
    children: [
      {
        path: 'clientes',
        name: 'clientes',
        meta: {
          breadcrumb: [{ name: 'Catálogos' }, { name: 'Clientes' }],
        },
        component: () =>
          import('@/views/catalogos/Clientes'),
      },
      {
        path: 'cliente/:idcliente',
      name: 'cliente',
        meta: {
          breadcrumb: [{ name: 'Catálogos' }, {name:'Cliente'}],
        },
        component: () =>
          import('@/views/catalogos/Cliente'),
        //  beforeEnter: authenticate,
          group:'catalogos'
      },
      {
        path: 'rutas/:idcliente',
      name: 'rutas',
        meta: {
          breadcrumb: [{ name: 'Catálogos' }, {name:'Rutas'}],
        },
        component: () =>
          import('@/views/catalogos/Rutas'),
        //  beforeEnter: authenticate,
          group:'catalogos'
      },{
        
        path: 'ruta/:idcliente/:idruta',
      name: 'ruta',
        meta: {
          breadcrumb: [{ name: 'Catálogos' }, {name:'Ruta'}],
        },
        component: () =>
          import('@/views/catalogos/Ruta'),
        //  beforeEnter: authenticate,
          group:'catalogos'
      
      },
      {
        path: 'operadores',
      name: 'operadores',
        meta: {
          breadcrumb: [{ name: 'Catálogos' }, {name:'Operadores'}],
        },
        component: () =>
          import('@/views/catalogos/Operadores'),
       //   beforeEnter: authenticate,
          group:'catalogos'
      },
      {
        path: 'operador/:idoperador',
      name: 'operador',
        meta: {
          breadcrumb: [{ name: 'Catálogos' }, {name:'Operador'}],
        },
        component: () =>
          import('@/views/catalogos/Operador'),
       //   beforeEnter: authenticate,
          group:'catalogos'
      }, {
        path: 'tractocamiones',
      name: 'tractocamiones',
        meta: {
          breadcrumb: [{ name: 'Catálogos' }, {name:'Tractocamiones'}],
        },
        component: () =>
          import('@/views/catalogos/TractoCamiones'),
        //  beforeEnter: authenticate,
          group:'catalogos'
      }, {
        path: 'tractocamion/:idtractocamion',
      name: 'tractocamion',
        meta: {
          breadcrumb: [{ name: 'Catálogos' }, {name:'Tractocamion'}],
        },
        component: () =>
          import('@/views/catalogos/Tractocamion'),
         // beforeEnter: authenticate,
          group:'catalogos'
      },
      {
        path: 'remolques',
      name: 'remolques',
        meta: {
          breadcrumb: [{ name: 'Catálogos' }, {name:'Remolques'}],
        },
        component: () =>
          import('@/views/catalogos/Remolques'),
       //   beforeEnter: authenticate,
          group:'catalogos'
      },{
        path: 'remolque/:idremolque',
      name: 'remolque',
        meta: {
          breadcrumb: [{ name: 'Catálogos' }, {name:'Remolque'}],
        },
        component: () =>
          import('@/views/catalogos/Remolque'),
        //  beforeEnter: authenticate,
          group:'catalogos'
      },
      {
        path: 'dollys',
      name: 'dollys',
        meta: {
          breadcrumb: [{ name: 'Catálogos' }, {name:'Dolly'}],
        },
        component: () =>
          import('@/views/catalogos/Dollys'),
        //  beforeEnter: authenticate,
          group:'catalogos'
      },{
        path: 'dolly/:iddolly',
      name: 'dolly',
        meta: {
          breadcrumb: [{ name: 'Catálogos' }, {name:'Dolly'}],
        },
        component: () =>
          import('@/views/catalogos/Dolly'),
        //  beforeEnter: authenticate,
          group:'catalogos'
      },{
        path: 'casetas',
      name: 'casetas',
        meta: {
          breadcrumb: [{ name: 'Catálogos' }, {name:'Caseta'}],
        },
        component: () =>
          import('@/views/catalogos/Casetas'),
        //  beforeEnter: authenticate,
          group:'catalogos'
      },{
        path: 'caseta/:idcaseta',
      name: 'caseta',
        meta: {
          breadcrumb: [{ name: 'Catálogos' }, {name:'Caseta'}],
        },
        component: () =>
          import('@/views/catalogos/Caseta'),
        //  beforeEnter: authenticate,
          group:'catalogos'
      }
      
    ]
  },{
    path:'/operacion',
    component: () => import('@/views/operacion/Operacion'),
   // beforeEnter: authenticate,
    group:'operacion',
    children: [
      {
        path: 'viajes',
        name: 'viajes',
        meta: {
          breadcrumb: [{ name: 'Operación' }, { name: 'Viajes' }],
        },
        component: () =>
          import('@/views/operacion/Viajes'),
      },
      {
        path: 'viaje/:idviaje',
        name: 'viaje',
        meta: {
          breadcrumb: [{ name: 'Operación' }, { name: 'Viajes' }],
        },
        component: () =>
          import('@/views/operacion/Viaje')
      }
      ]

  },{
    path:'/sistema',
    component: () => import('@/views/sistema/Sistema'),
   // beforeEnter: authenticate,
    group:'sistema',
    children: [
      {
        path: 'perfiles',
        name: 'perfiles',
        meta: {
          breadcrumb: [{ name: 'Sistema' }, { name: 'Perfil' }],
        },
        component: () =>
          import('@/views/sistema/Perfiles'),
      },
      {
        path: 'usuarios',
        name: 'usuarios',
        meta: {
          breadcrumb: [{ name: 'Sistema' }, { name: 'Usuarios' }],
        },
        component: () =>
          import('@/views/sistema/Usuarios')
      },
      
      ]
  },
  {
    path: '/salir',
    name: 'salir',
    //beforeEnter: authenticate,
    group:'sesion',
    component: () =>
          import('@/views/Sesion/CerrarSesion')
      

  }

      ],
    }
  ]

 
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch('changeThemeLoadingState', true);
  }
  next();
});

router.afterEach(() => {
  // Remove initial loading
  // const gullPreLoading = document.getElementById("loading_wrap");
  // if (gullPreLoading) {
  //   gullPreLoading.style.display = "none";
  // }
  // Complete the animation of the route progress bar.
  setTimeout(() => store.dispatch('changeThemeLoadingState', false), 500);
  // NProgress.done();
  // if (isMobile) {
  // if (window.innerWidth <= 1200) {
  //   // console.log("mobile");
  //   store.dispatch("changeSidebarProperties");
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
  //     store.dispatch("changeCompactSidebarProperties");
  //   }
  // } else {
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  // }
});

export default router;
