/*
 * ============================
 * File: main.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Tuesday, 14th April 2020 7:17:10 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import vuetify from './plugins/vuetify';
import './plugins';
import VueSweetalert2 from 'vue-sweetalert2'
import octaviaKit from '@/plugins/octavia.kit';
import VueApexCharts from 'vue-apexcharts';
import 'sweetalert2/dist/sweetalert2.min.css';
import DatetimePicker from 'vuetify-datetime-picker'


// mock
import './fake-db/index.js';

Vue.component('apexchart', VueApexCharts);
Vue.use(VueSweetalert2)
Vue.use(DatetimePicker)
Vue.use(octaviaKit);
Vue.config.productionTip = false;
// if (process.env.NODE_ENV === "development") {
//   makeServer();
// }

export const url = 'https://gruposaeta.com.mx/wsGSaeta/'


Vue.filter('formatDate', function(value) {
  if (value) {
    return moment.tz(String(value),'America/Mexico_City').format('DD/MM/YYYY')
  }
})

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
