// Vuetify Documentation https://vuetifyjs.com

import Vue from "vue";
import Vuetify from "vuetify/lib";
import { themePreset } from "../../src/themeConfig";
import VStepperIcon from "@/components/VStepperIcon.vue";
import VStepperStepIcon from "@/components/VStepperIcon.vue"

Vue.use(Vuetify, {
  components: {
    VStepperIcon,
    VStepperStepIcon,
  },
});
import es from "vuetify/lib/locale/es";

export default new Vuetify({
  theme: themePreset.theme,
  rtl: themePreset.rtl,
  lang: {
    locales: { es },
    current: 'es',
  }
});
